// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-question-tsx": () => import("./../src/templates/question.tsx" /* webpackChunkName: "component---src-templates-question-tsx" */),
  "component---src-templates-answer-tsx": () => import("./../src/templates/answer.tsx" /* webpackChunkName: "component---src-templates-answer-tsx" */),
  "component---src-pages-about-tsx": () => import("./../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

